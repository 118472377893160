import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';





const Nav = () => {

  const GoWhatsapp = () => {
    window.open('https://wa.me/917868940786?text=Sir%2C%20I%20Need%20Id%20With%20Best%20Offer', '_blank');
  }

  const GoInsta = () => {
    window.open('https://www.instagram.com/styleexchnange', '_blank');
  }

  const GoTele = () => {
    window.open('https://t.me/styleexchange23', '_blank');
  }


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
      <div className='w100 LogoNameInNav' > <img src="logoo.png" alt="" /> </div>
      <div className='MenuBox' >
        <Link to='/login' >
          <div className='MenuDiv' >
            <div className='pdR5'><img src="/icon/bookk.png" alt="" /></div>
            <div  >My Market</div>
          </div>
        </Link>

        <Link to='/Cricket' >
          <div className='MenuDiv' >
            <div className='pdR5'> <img src="/icon/cricket.png" alt="" /> </div>
            <div>Cricket</div>
          </div>
        </Link>

        <Link to='/Football' className='MenuDiv' >
          <div className='pdR5'> <img src="/icon/football.png" alt="" /> </div>
          <div>Football</div>
        </Link>



        <Link to='/Login' className='MenuDiv' ><div className='pdR5'><img src="/icon/card.png" alt="" /></div>
          <div  >Indian Card Game</div></Link>
        <Link to='/Login' className='MenuDiv' > <div className='pdR5'><img src="/icon/tennis.png" alt="" /></div>
          <div  >Tennis</div></Link>
        <Link to='/Login' className='MenuDiv' ><div className='pdR5'><img src="/icon/winner.png" alt="" /></div>
          <div  >Sports Book</div></Link>
        <Link to='/Login' className='MenuDiv' ><div className='pdR5'><img src="/icon/dice.png" alt="" /></div>
          <div  >Slot Games</div></Link>
        <Link to='/Login' className='MenuDiv' ><div className='pdR5'><img src="/icon/chip.png" alt="" /></div>
          <div  >Casino</div></Link>



        <div className='MenuDiv' >
          <div style={{ color: '#005837', margin: '0 auto' }} >Get Instant Id On Whatsapp</div>
        </div>

        <div className='AboutIcon'>
                <div onClick={GoWhatsapp} ><img src="w.svg" alt="" /></div>
                <div onClick={GoInsta}><img src="i.svg" alt="" /></div>
                <div onClick={GoTele}><img src="t.svg" alt="" /></div>
            </div>
      </div>
    </>

  );

  return (
    <div className='NavBar' >
      <div className='w20' onClick={toggleDrawer('left', true)} ><MenuIcon /></div>
      <div className='w100 LogoName' > <img src="logoo.png" alt="" /> </div>
      <div className='w30' ><NavLink to="/Login"> <span className='LoginBtn' > Login </span> </NavLink>  </div>


      <React.Fragment key={'left'}>
        <SwipeableDrawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
          disableSwipeToOpen={true}
          disableDiscovery={true}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>

    </div>
  )
}

export default Nav